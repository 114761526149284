import * as fwx from "../../../../framework/forms/form-export";
import {
    EventLinkAnmeldungEditUtils
} from "./event-link-anmeldung-edit-utils";

@fwx.autoinject
export class EventLinkAnmeldungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EventLinkAnmeldungEditUtils: EventLinkAnmeldungEditUtils) {
        super(element, formBaseImport);
        this.id = "eventLinkAnmeldungEdit";
        this.title = "eventLinkAnmeldungEdit.eventlinkanmeldungedit_caption";
        this.addModel({
            "id": "$m_EventLinkAnmeldung",
            "webApiAction": "ZIT/Objekte/EventLinkAnmeldung",
            "webApiExpand": {
                'Element': null
            },
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "allowDelete": "true",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventAnmeldungsgruppe",
            "webApiAction": "ZIT/Stammdaten/EventAnmeldungsgruppe",
            "key": "models.data.$m_EventLinkAnmeldung.Element.IdEventAnmeldungsgruppe",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_EventLinkAnmeldungEditUtils", $f_EventLinkAnmeldungEditUtils, "functions.$f_EventLinkAnmeldungEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "geschlecht",
            "filters": [],
            "caption": "eventLinkAnmeldungEdit.r_geschlecht_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.Geschlecht",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.Geschlecht",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geschlecht",
            "options": {
                "optionsName": "r_geschlechtOptions",
                "optionsNameFQ": "r_geschlechtOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "eventLinkAnmeldungEdit.r_vorname_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.Vorname",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.Vorname",
                "propertyPrefix": "Element"
            },
            "validationRules": [],
            "id": "r_vorname",
            "options": {
                "optionsName": "r_vornameOptions",
                "optionsNameFQ": "r_vornameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "eventLinkAnmeldungEdit.r_nachname_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.Nachname",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.Nachname",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_nachname",
            "options": {
                "optionsName": "r_nachnameOptions",
                "optionsNameFQ": "r_nachnameOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "eventLinkAnmeldungEdit.r_telefonlandvorwahl_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.TelefonLandvorwahl",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.TelefonLandvorwahl",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_telefonLandvorwahl",
            "options": {
                "optionsName": "r_telefonLandvorwahlOptions",
                "optionsNameFQ": "r_telefonLandvorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "eventLinkAnmeldungEdit.r_telefon_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.Telefon",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.Telefon",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "telefon",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_telefon",
            "options": {
                "optionsName": "r_telefonOptions",
                "optionsNameFQ": "r_telefonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "eventLinkAnmeldungEdit.r_email_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.Email",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.Email",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "eventAnmeldungsgruppe",
            "filters": [],
            "caption": "eventLinkAnmeldungEdit.r_anmeldungsgruppe_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.IdEventAnmeldungsgruppe",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.IdEventAnmeldungsgruppe",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_anmeldungsgruppe",
            "options": {
                "optionsName": "r_anmeldungsgruppeOptions",
                "optionsNameFQ": "r_anmeldungsgruppeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 10.0,
            "max": 99.0,
            "caption": "eventLinkAnmeldungEdit.r_alter_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.Alter",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.Alter",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_alter",
            "options": {
                "optionsName": "r_alterOptions",
                "optionsNameFQ": "r_alterOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "ausbildung",
            "filters": [],
            "caption": "eventLinkAnmeldungEdit.r_ausbildung_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.IdAusbildung",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.IdAusbildung",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_ausbildung",
            "options": {
                "optionsName": "r_ausbildungOptions",
                "optionsNameFQ": "r_ausbildungOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "eventLinkAnmeldungEdit.r_klasse_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.Klasse",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.Klasse",
                "propertyPrefix": "Element"
            },
            "validationRules": [],
            "id": "r_klasse",
            "options": {
                "optionsName": "r_klasseOptions",
                "optionsNameFQ": "r_klasseOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 1.0,
            "max": 999.0,
            "caption": "eventLinkAnmeldungEdit.r_anzahlteilnehmer_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.AnzahlTeilnehmer",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.AnzahlTeilnehmer",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_anzahlTeilnehmer",
            "options": {
                "optionsName": "r_anzahlTeilnehmerOptions",
                "optionsNameFQ": "r_anzahlTeilnehmerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "eventLinkAnmeldungEdit.r_eventerfahrendurch_caption",
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.EventErfahrenDurch",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.EventErfahrenDurch",
                "propertyPrefix": "Element"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_eventErfahrenDurch",
            "options": {
                "optionsName": "r_eventErfahrenDurchOptions",
                "optionsNameFQ": "r_eventErfahrenDurchOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "eventLinkAnmeldungEdit.r_startdatum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.StartDatum",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.StartDatum",
                "propertyPrefix": "Element"
            },
            "validationRules": [],
            "id": "r_startDatum",
            "options": {
                "optionsName": "r_startDatumOptions",
                "optionsNameFQ": "r_startDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "eventLinkAnmeldungEdit.r_startuhrzeit_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.StartUhrzeit",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.StartUhrzeit",
                "propertyPrefix": "Element"
            },
            "validationRules": [],
            "id": "r_startUhrzeit",
            "options": {
                "optionsName": "r_startUhrzeitOptions",
                "optionsNameFQ": "r_startUhrzeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "eventLinkAnmeldungEdit.r_endedatum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.EndeDatum",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.EndeDatum",
                "propertyPrefix": "Element"
            },
            "validationRules": [],
            "id": "r_endeDatum",
            "options": {
                "optionsName": "r_endeDatumOptions",
                "optionsNameFQ": "r_endeDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "eventLinkAnmeldungEdit.r_endeuhrzeit_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_EventLinkAnmeldung",
                "bindTo": "Element.EndeUhrzeit",
                "bindToFQ": "models.data.$m_EventLinkAnmeldung.Element.EndeUhrzeit",
                "propertyPrefix": "Element"
            },
            "validationRules": [],
            "id": "r_endeUhrzeit",
            "options": {
                "optionsName": "r_endeUhrzeitOptions",
                "optionsNameFQ": "r_endeUhrzeitOptions"
            }
        });
        super.onConstructionFinished();
    }
}