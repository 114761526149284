import { IdxPopoverComponent } from "../../interfaces/dx-components";
import { NachrichtService } from "../../services/nachricht-service";
import { autoinject, bindable, observable } from 'aurelia-framework';
import { FileService, RestService } from '../../../framework/base/export';
import { ListView } from '../../../framework/forms/export';
import { IDatei } from "../datei/datei";
import { IListViewOptions } from './../../../framework/forms/elements/list-view/list-view-options';
import { IZitTagOptions } from '../../../zit-ui/elements/zit-tag/export';
import { CustomEditPopupService } from './../../services/export';
import { IZitToolbarOptions } from '../../../zit-ui/elements/zit-toolbar/zit-toolbar-options';

@autoinject
export class NotizListView {
  constructor(
    private _element: Element,
    private _customEditPopupService: CustomEditPopupService,
    private _fileService: FileService,
    private _nachrichtService: NachrichtService,
    private _restService: RestService,
  ) { }

  @bindable @observable idPerson: number;
  
  notizList: Notiz[] = [];
  notiz: Notiz = null;

  tagOptions: IZitTagOptions;
  
  notizListView: ListView;
  notizListViewOptions: IListViewOptions = {
    showReloadButton: false,
    useDefaultListItemStyle: false,
    itemClass: "z--list-view-inline-item",
    dataSource: new DevExpress.data.DataSource(new DevExpress.data.ArrayStore({data: this.notizList}))
  };

  notizTagOptions: IZitTagOptions = {
    isClickEnabled: true,
    hasDefaultInfo: true,
    icon: {
      faIcon: "far fa-sticky-note"
    },
    infoList: [{
      icon: {
        faIcon: "fas fa-paperclip",
      },
      isVisibleExpression: `item.HasDateien`
    }],
    textExpression: `item.Text | zitRestrictLength:50`,
    onClick: (e, data) => {
      e.stopPropagation();
      
      this.notiz = data;
      this.id = "c" + new Date().getTime().toString();
      
      this.notizPopover.setOption({
        target: e.currentTarget,
      });
      
      this.notizPopover.instance.show()
    }
  };

  id: string;
  isPopoverVisible = false;
  notizPopover: IdxPopoverComponent;
  notizPopoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    onHiding: () => this.isPopoverVisible = false,
    onShowing: () => this.isPopoverVisible = true,
    position: {
      at: {
        x: "left",
        y: "bottom"
      },
      my: {
        x: "left",
        y: "top"
      }
    }
  };
  
  bind() {
    this.tagOptions = this.notizTagOptions;

    this.loadData();
  }
  unbind() {
  }

  idPersonChanged(newVal) {
    this.loadData();
  }

  private async loadData() {
    const data: any[] = await this.loadDataInt();
    this.notizList.splice(0, this.notizList.length, ...data);

    if (this.notizListView.options) {
      this.notizListView.refresh();
    }
  }
  private async loadDataInt(): Promise<Notiz[]> {
    if (!this.idPerson) return [];

    const benutzer = await this._nachrichtService.getBenutzerFromObjekt("TIP.ZIT.Business.Entities.Objekte.Personen.Person", this.idPerson);
    if (!benutzer) return [];

    const result = await this._restService.post({
      url: this._restService.getApiUrl("ZIT/Nachricht/Nachricht"),
      data: {
        Take: 100,
        Skip: 0,
        IdBenutzer: benutzer.Id,
        IsGruppe: false,
        IsStellvertreter: false,
        NurNotizen: true
      }
    });
    
    if (!result || !result.NachrichtList) return [];

    const div = document.createElement("div");
    return result.NachrichtList.map(i => {
      div.innerHTML = (i.Text ?? "").replaceAll("<br>", " ");

      return {
        Text: div.textContent,
        TextHtml: i.Text,
        HasDateien: (i.DateiList ?? []).length > 0,
        Dateien: (i.DateiList ?? [])
      } as Notiz;
    });
  }
}

type Notiz = {
  Text: string;
  TextHtml: string;
  HasDateien: boolean;
  Dateien: IDatei[];
}
