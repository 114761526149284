import * as fwx from "../../../../framework/forms/form-export";
import {
    EventZeitraumEditUtils
} from "./event-zeitraum-edit-utils";

@fwx.autoinject
export class EventZeitraumEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EventZeitraumEditUtils: EventZeitraumEditUtils) {
        super(element, formBaseImport);
        this.id = "event-zeitraum-edit";
        this.title = "event-zeitraum-edit.event-zeitraum-edit_caption";
        this.addModel({
            "id": "$m_EventZeitraum",
            "webApiAction": "ZIT/Objekte/EventZeitraum",
            "webApiExpand": {
                'Kopf': {
                    'expand': {
                        'Stelleninserate': null
                    }
                },
                'Event': {
                    'expand': {
                        'Kopf': null
                    }
                }
            },
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_EventZeitraumKopf",
            "webApiAction": "ZIT/Objekte/EventZeitraumKopfElement",
            "key": "models.data.$m_EventZeitraum.IdEventZeitraumKopfElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Event",
            "webApiAction": "ZIT/Objekte/Event",
            "webApiExpand": {
                'Kopf': null
            },
            "modificationInfoEnabled": true,
            "key": "models.data.$m_EventZeitraum.IdEvent",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventTyp",
            "webApiAction": "ZIT/Stammdaten/EventTyp",
            "key": "models.data.$m_Event.Kopf.IdEventTyp",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventZeitraumKontingentRel",
            "webApiAction": "ZIT/Objekte/EventZeitraumKontingentElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventZeitraumKontingentEdit",
            "webApiAction": "ZIT/Objekte/EventZeitraumKontingentElement",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventZeitraumStelleninseratRel",
            "webApiAction": "ZIT/Objekte/EventZeitraumKopfElementZuStelleninserat",
            "webApiExpand": {
                'Stelleninserat': null
            },
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EventZeitraumStelleninseratEdit",
            "webApiAction": "ZIT/Objekte/EventZeitraumKopfElementZuStelleninserat",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EventZeitraumEditUtils.actionCommand",
                "bindToFQ": "functions.$f_EventZeitraumEditUtils.actionCommand"
            }
        });
        this.addEditPopup({
            "idContent": "eventZeitraumKontingentEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_EventZeitraumKontingentEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_EventZeitraumKontingentEdit.Id"
                }
            }],
            "id": "eventZeitraumKontingentEditPopup",
            "options": {
                "optionsName": "eventZeitraumKontingentEditPopupOptions",
                "optionsNameFQ": "eventZeitraumKontingentEditPopupOptions"
            },
            "height": "auto",
            "width": "500px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "eventZeitraumStelleninseratEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_EventZeitraumStelleninseratEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_EventZeitraumStelleninseratEdit.Id"
                }
            }, {
                "to": "$v_idEvent",
                "binding": {
                    "dataContext": "$m_Event",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Event.Id"
                }
            }],
            "id": "eventZeitraumStelleninseratEditPopup",
            "options": {
                "optionsName": "eventZeitraumStelleninseratEditPopupOptions",
                "optionsNameFQ": "eventZeitraumStelleninseratEditPopupOptions"
            },
            "height": "auto",
            "width": "540px",
            "commands": []
        });
        this.addFunction("$f_EventZeitraumEditUtils", $f_EventZeitraumEditUtils, "functions.$f_EventZeitraumEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "event-zeitraum-edit.r_startdatum_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.StartDatum",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.StartDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_EventZeitraumEditUtils.onStartDatumChanged",
            "id": "r_startDatum",
            "options": {
                "optionsName": "r_startDatumOptions",
                "optionsNameFQ": "r_startDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-zeitraum-edit.r_startuhrzeit_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.StartUhrzeit",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.StartUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_EventZeitraum",
                    "bindTo": "$f_EventZeitraumEditUtils.startUhrzeitValidationRule",
                    "bindToFQ": "functions.$f_EventZeitraumEditUtils.startUhrzeitValidationRule"
                }
            }],
            "id": "r_startUhrzeit",
            "options": {
                "optionsName": "r_startUhrzeitOptions",
                "optionsNameFQ": "r_startUhrzeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "event-zeitraum-edit.r_endedatum_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.EndeDatum",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.EndeDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_EventZeitraum",
                    "bindTo": "$f_EventZeitraumEditUtils.endeDatumValidationRule",
                    "bindToFQ": "functions.$f_EventZeitraumEditUtils.endeDatumValidationRule"
                }
            }, {
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_endeDatum",
            "options": {
                "optionsName": "r_endeDatumOptions",
                "optionsNameFQ": "r_endeDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-zeitraum-edit.r_endeuhrzeit_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.EndeUhrzeit",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.EndeUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_EventZeitraum",
                    "bindTo": "$f_EventZeitraumEditUtils.endeUhrzeitValidationRule",
                    "bindToFQ": "functions.$f_EventZeitraumEditUtils.endeUhrzeitValidationRule"
                }
            }],
            "id": "r_endeUhrzeit",
            "options": {
                "optionsName": "r_endeUhrzeitOptions",
                "optionsNameFQ": "r_endeUhrzeitOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-zeitraum-edit.r_meldenum_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.MeldenUm",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.MeldenUm",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_meldenUm",
            "options": {
                "optionsName": "r_meldenUmOptions",
                "optionsNameFQ": "r_meldenUmOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "min": 0.0,
            "max": 99999.0,
            "format": "n0",
            "caption": "event-zeitraum-edit.r_kontingentgesamt_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.KontingentGesamt",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.KontingentGesamt",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$f_EventZeitraumEditUtils",
                    "bindTo": "kontingentValidationRule",
                    "bindToFQ": "functions.$f_EventZeitraumEditUtils.kontingentValidationRule"
                }
            }],
            "id": "r_kontingentGesamt",
            "options": {
                "optionsName": "r_kontingentGesamtOptions",
                "optionsNameFQ": "r_kontingentGesamtOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "event-zeitraum-edit.r_anmeldeschlussdatum_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.AnmeldeschlussDatum",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.AnmeldeschlussDatum",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_EventZeitraum",
                    "bindTo": "$f_EventZeitraumEditUtils.anmeldeschlussDatumValidationRule",
                    "bindToFQ": "functions.$f_EventZeitraumEditUtils.anmeldeschlussDatumValidationRule"
                }
            }],
            "id": "r_anmeldeschlussDatum",
            "options": {
                "optionsName": "r_anmeldeschlussDatumOptions",
                "optionsNameFQ": "r_anmeldeschlussDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "t",
            "caption": "event-zeitraum-edit.r_anmeldeschlussuhrzeit_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.AnmeldeschlussUhrzeit",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.AnmeldeschlussUhrzeit",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$m_EventZeitraum",
                    "bindTo": "$f_EventZeitraumEditUtils.anmeldeschlussUhrzeitValidationRule",
                    "bindToFQ": "functions.$f_EventZeitraumEditUtils.anmeldeschlussUhrzeitValidationRule"
                }
            }],
            "id": "r_anmeldeschlussUhrzeit",
            "options": {
                "optionsName": "r_anmeldeschlussUhrzeitOptions",
                "optionsNameFQ": "r_anmeldeschlussUhrzeitOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_kontingentGeschaeftspartnerSchule",
                "caption": "event-zeitraum-edit.r_kontingentgeschaeftspartnerschule_caption",
                "bindTo": "GeschaeftspartnerSchuleBezeichnung"
            }, {
                "id": "r_kontingentKontingent",
                "caption": "event-zeitraum-edit.r_kontingentkontingent_caption",
                "bindTo": "Kontingent",
                "format": "n0"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_eventZeitraumKontingentGridToolbarOptions",
                "optionsNameFQ": "r_eventZeitraumKontingentGridToolbarOptions"
            },
            "caption": "event-zeitraum-edit.r_eventzeitraumkontingentgrid_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_EventZeitraumKontingentRel",
                "bindTo": "IdEventZeitraum",
                "bindToFQ": "models.data.$m_EventZeitraumKontingentRel.IdEventZeitraum"
            },
            "dataModel": "$m_EventZeitraum",
            "editDataContext": "$m_EventZeitraumKontingentEdit",
            "idEditPopup": "eventZeitraumKontingentEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_eventZeitraumKontingentGrid",
            "options": {
                "optionsName": "r_eventZeitraumKontingentGridOptions",
                "optionsNameFQ": "r_eventZeitraumKontingentGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_stelleninserat",
                "caption": "event-zeitraum-edit.r_stelleninserat_caption",
                "bindTo": "Stelleninserat.StellenbezeichnungExtern",
                "onCellPrepared": "functions.$f_EventZeitraumEditUtils.onStelleninseratBezeichnungCellPrepared"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_eventZeitraumStelleninseratGridToolbarOptions",
                "optionsNameFQ": "r_eventZeitraumStelleninseratGridToolbarOptions"
            },
            "caption": "event-zeitraum-edit.r_eventzeitraumstelleninseratgrid_caption",
            "binding": {
                "dataContext": "$m_EventZeitraumKopf"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_EventZeitraumStelleninseratRel",
                "bindTo": "IdEventZeitraumKopfElement",
                "bindToFQ": "models.data.$m_EventZeitraumStelleninseratRel.IdEventZeitraumKopfElement"
            },
            "dataModel": "$m_EventZeitraumKopf",
            "editDataContext": "$m_EventZeitraumStelleninseratEdit",
            "idEditPopup": "eventZeitraumStelleninseratEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_eventZeitraumStelleninseratGrid",
            "options": {
                "optionsName": "r_eventZeitraumStelleninseratGridOptions",
                "optionsNameFQ": "r_eventZeitraumStelleninseratGridOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "event-zeitraum-edit.r_veroeffnetlichenfiltergeschaeftspartner_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.VeroeffentlichenFilterGeschaeftspartner",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.VeroeffentlichenFilterGeschaeftspartner",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_veroeffnetlichenFilterGeschaeftspartner",
            "options": {
                "optionsName": "r_veroeffnetlichenFilterGeschaeftspartnerOptions",
                "optionsNameFQ": "r_veroeffnetlichenFilterGeschaeftspartnerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "event-zeitraum-edit.r_betreff_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.Betreff",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.Betreff",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "event-zeitraum-edit.r_bezeichnungintern_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.BezeichnungIntern",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.BezeichnungIntern",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_bezeichnungIntern",
            "options": {
                "optionsName": "r_bezeichnungInternOptions",
                "optionsNameFQ": "r_bezeichnungInternOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "event-zeitraum-edit.r_ort_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.Ort",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.Ort",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_ort",
            "options": {
                "optionsName": "r_ortOptions",
                "optionsNameFQ": "r_ortOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "height": "250px",
            "showClearButton": false,
            "caption": "event-zeitraum-edit.r_dmslinklogo_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.DMSLinkLogo",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.DMSLinkLogo",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [],
            "id": "r_dMSLinkLogo",
            "options": {
                "optionsName": "r_dMSLinkLogoOptions",
                "optionsNameFQ": "r_dMSLinkLogoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "min": 1.0,
            "max": 999.0,
            "format": "n0",
            "caption": "event-zeitraum-edit.r_standarddauer_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.Standarddauer",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.Standarddauer",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$f_EventZeitraumEditUtils",
                    "bindTo": "getRequiredValidationRule('Standarddauer', 'Standarddauer')",
                    "bindToFQ": "functions.$f_EventZeitraumEditUtils.getRequiredValidationRule('Standarddauer', 'Standarddauer')"
                }
            }],
            "id": "r_standardDauer",
            "options": {
                "optionsName": "r_standardDauerOptions",
                "optionsNameFQ": "r_standardDauerOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "min": 1.0,
            "max": 999.0,
            "format": "n0",
            "caption": "event-zeitraum-edit.r_minimaldauer_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.Mindestdauer",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.Mindestdauer",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$f_EventZeitraumEditUtils",
                    "bindTo": "getRequiredValidationRule('Mindestdauer', 'Mindestdauer')",
                    "bindToFQ": "functions.$f_EventZeitraumEditUtils.getRequiredValidationRule('Mindestdauer', 'Mindestdauer')"
                }
            }],
            "id": "r_minimalDauer",
            "options": {
                "optionsName": "r_minimalDauerOptions",
                "optionsNameFQ": "r_minimalDauerOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "min": 1.0,
            "max": 999.0,
            "format": "n0",
            "caption": "event-zeitraum-edit.r_maximaldauer_caption",
            "binding": {
                "dataContext": "$m_EventZeitraum",
                "bindTo": "Kopf.Maximaldauer",
                "bindToFQ": "models.data.$m_EventZeitraum.Kopf.Maximaldauer",
                "propertyPrefix": "Kopf"
            },
            "validationRules": [{
                "binding": {
                    "dataContext": "$f_EventZeitraumEditUtils",
                    "bindTo": "getRequiredValidationRule('Maximaldauer', 'Maximaldauer')",
                    "bindToFQ": "functions.$f_EventZeitraumEditUtils.getRequiredValidationRule('Maximaldauer', 'Maximaldauer')"
                }
            }],
            "id": "r_maximalDauer",
            "options": {
                "optionsName": "r_maximalDauerOptions",
                "optionsNameFQ": "r_maximalDauerOptions"
            }
        });
        super.onConstructionFinished();
    }
}