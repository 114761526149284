import {
  autoinject,
  bindable,
  computedFrom,
  observable,
  BindingEngine,
  Disposable,
  Expression,
  OverrideContext,
  TaskQueue,
  Scope
} from "aurelia-framework";
import {
  IFileUploaderWithViewerOptions
} from "../../widget-options/export";
import {
  BindingService,
  FileService,
  LocalizationService,
  ScopeContainer
} from "../../../base/export";

import { IToolbarOptions } from '../toolbar/toolbar-options';
import { ModelUtilsService } from '../../services/model-utils-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class TipFileUploaderWithViewer {
  private _isClickActive: boolean;

  constructor(
    private element: Element,
    private file: FileService,
    private localization: LocalizationService,
    private binding: BindingService,
    private bindingEngine: BindingEngine,
    private taskQueue: TaskQueue,
    private modelUtilsService: ModelUtilsService,
    private eventAggregator: EventAggregator
  ) { }

  @bindable options: IFileUploaderWithViewerOptions;
  @observable currentValue: any;

  scope: Scope;
  scopeContainer: ScopeContainer;

  input: HTMLInputElement;
  downloadUrl: string;
  showImage: boolean;

  bindingContext: any;
  overrideContext: OverrideContext;

  observables: Disposable[] = [];
  dropEnabled: boolean = false;

  isReadOnly: boolean;
  isDisabled: boolean;

  placeholderIcon?: string;
  placeholderImage?: string;
  placeholderImageText?: string;
  placeholder?: string;
  @observable iconDownload?: string;
  imageContainerStyle: any;

  toolbarTopOptions: IToolbarOptions;
  toolbarBottomOptions: IToolbarOptions;

  downloadButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Download",
    onClick: () => {
      window.open(this.downloadUrl, "_blank");
    }
  }
  clearButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "far fa-trash-alt",
    onClick: () => {
      this.setValue(null);
    }
  }

  @computedFrom("options.showViewer", "downloadUrl")
  get showViewer(): boolean {
    return this.downloadUrl
      && (!this.options
        || this.options.showViewer == void (0)
        || this.options.showViewer == true);
  }
  @computedFrom("showViewer", "options.acceptType")
  get showImageViewer(): boolean {
    return this.showViewer
      && this.options
      && this.options.acceptType == "image/*";
  }
  @computedFrom("dropEnabled")
  get clickableClass(): string {
    if (this.dropEnabled) {
      return "t--file-uploader-with-viewer-click-region-droppable";
    } else {
      return "";
    }
  }
  @computedFrom("isReadOnly", "isDisabled")
  get canUpload(): boolean {
    return !this.isReadOnly && !this.isDisabled;
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.bindingContext = bindingContext;
    this.overrideContext = overrideContext;

    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    const bindingOptions = this.options["bindingOptions"];
    if (bindingOptions && bindingOptions.value) {
      this.observeValue(bindingOptions.value, (v) => this.currentValue = v);
    }

    if (this.options.iconDownloadExpression) {
      this.observeValue(this.options.iconDownloadExpression, (v) => this.iconDownload = v);
    } else if (this.options.iconDownload) {
      this.iconDownload = this.options.iconDownload;
    }

    if (this.options.placeholderImageExpression) {
      this.observeValue(this.options.placeholderImageExpression, (v) => this.placeholderImage = v);
    } else if (this.options.placeholderImage) {
      this.placeholderImage = this.options.placeholderImage;
    }

    if (this.options.placeholderIconExpression) {
      this.observeValue(this.options.placeholderIconExpression, (v) => this.placeholderIcon = v);
    } else if (this.options.placeholderIcon) {
      this.placeholderIcon = this.options.placeholderIcon;
    }

    if (this.options.isReadOnlyExpression) {
      this.observeValue(this.options.isReadOnlyExpression, (v) => this.isReadOnly = v);
    } else if (this.options.isReadOnly) {
      this.isReadOnly = this.options.isReadOnly;
    }

    if (this.options.isDisabledExpression) {
      this.observeValue(this.options.isDisabledExpression, (v) => this.isDisabled = v);
    } else if (this.options.isDisabled) {
      this.isDisabled = this.options.isDisabled;
    }

    if (this.options.showDownloadButton == void(0)) {
      this.options.showDownloadButton = true;
    }

    this.placeholderImageText = this.options.placeholderImageText;
    this.placeholder = this.localization.translateOnce("forms.file_uploadClickHere");
    if (this.options.placeholder) {
      this.placeholder = this.options.placeholder;
    }

    this.imageContainerStyle = {
      height: this.options.height
        ? this.options.height
        : "150px"
    };

    this.eventAggregator.publish("file-uploader:bind", {
      element: this.element,
      options: this.options
    });

    this.createToolbarTop();
    this.createToolbarBottom();
  }
  unbind() {
    if (this.observables) {
      this.observables.forEach(c => c.dispose());
      this.observables.length = 0;
    }
    this.scopeContainer.disposeAll();
    this.scope = null;
  }
  attached() {
    this.input.addEventListener("change", (e) => {
      if (this.input.files.length !== 1) {
        return;
      }

      this.uploadFile(this.input.files[0]);
    });
  }

  getExpressionContext(propertyName: string) {
    return this.binding.getBindingContext({
      bindingContext: this.bindingContext,
      overrideContext: this.overrideContext
    }, propertyName);
  }
  observeValue(propertyName: string, setValueCallback: { (value): void }) {
    const expression = this.bindingEngine.parseExpression(propertyName);
    const context = this.getExpressionContext(propertyName);
    const observer = this.bindingEngine.expressionObserver(context, propertyName);

    this.observables.push(observer.subscribe((newValue, oldValue) => {
      setValueCallback(expression.evaluate({
        bindingContext: this.bindingContext,
        overrideContext: this.overrideContext
      }));
    }));

    setValueCallback(expression.evaluate({
      bindingContext: this.bindingContext,
      overrideContext: this.overrideContext
    }));
  }

  currentValueChanged(newValue: string) {
    if (newValue) {
      this.downloadUrl = this.file.getDownloadUrl(newValue);
    } else {
      this.downloadUrl = null;
    }

    this.taskQueue.queueTask(() => {
      //HACK notwendig, da Firefox das Bild ansonsten ev. außerhalb des Borders anzeigt
      this.showImage = !!this.downloadUrl;
    })
  }
  iconDownloadChanged(newValue: string) {
    let downloadButton: DevExpress.ui.dxButton = this["downloadButton"];
    const icon = `fa fa-${this.iconDownload || "cloud-download"}`;

    if (downloadButton) {
      downloadButton.option("icon", icon);
    } else {
      this.downloadButtonOptions.icon = icon;
    }
  }

  onClick() {
    if (!this.canUpload) {
      return;
    }

    this.input.click();
    
    event.stopPropagation();
    event.preventDefault();
  }
  onDragOver(event: Event) {
    event.preventDefault();
    this.dropEnabled = this.isDropEnabled(event);
  }
  onDragLeave(event: Event) {
    event.preventDefault();
    this.dropEnabled = false;
  }
  onDrop(event: any) {
    event.preventDefault();

    if (!this.isDropEnabled(event)) {
      return;
    }

    this.uploadFile(event.dataTransfer.files[0]);
    this.dropEnabled = false;
  }

  setValue(val: any) {
    const bindingOptions = this.options["bindingOptions"];
    if (bindingOptions && bindingOptions.value) {
      const expression = this.bindingEngine.parseExpression(bindingOptions.value);
      expression.assign({
        bindingContext: this.bindingContext,
        overrideContext: this.overrideContext
      }, val, null);
    }

    if (this.options.onFileKeyChanged) {
      this.options.onFileKeyChanged(val);
    }

    if (this.options && this.options.clearCurrentValueAfterUpload) {
      this.currentValue = null;
    }
  }

  private createToolbarTop() {
    if (!this.options.showToolbar) {
      return;
    }

    this.toolbarTopOptions = {
      title: this.options.caption,
      items: [],
      smallToolbar: true
    };

    this.toolbarTopOptions.items.push({
      id: "file-upload",
      icon: "fas fa-upload",
      isEnabledExpression: "canUpload",
      execute: () => {
        this.onClick();
      }
    });
    this.toolbarTopOptions.items.push({
      id: "file-delete",
      icon: "fas fa-times",
      isEnabledExpression: "canUpload",
      execute: () => {
        this.setValue(null);
      }
    });

    this.eventAggregator.publish("file-uploader:create-toolbar-top", {
      element: this.element,
      options: this.options,
      instance: this,
      toolbarOptions: this.toolbarTopOptions
    });
  }
  private createToolbarBottom() {
    if (!this.options.showToolbarBottom) {
      return;
    }

    this.toolbarBottomOptions = {
      title: null,
      items: [],
      smallToolbar: true
    };

    this.eventAggregator.publish("file-uploader:create-toolbar-bottom", {
      element: this.element,
      options: this.options,
      instance: this,
      toolbarOptions: this.toolbarBottomOptions
    });
  }
  private isDropEnabled(event: any): boolean {
    if (!this.canUpload) {
      return false;
    }

    if (!event.dataTransfer || !event.dataTransfer.types || event.dataTransfer.types.length !== 1) {
      return false;
    }

    return true;
  }
  private uploadFile(file: File) {
    if (this.options && this.options.maxFileSizeMB && file.size > this.options.maxFileSizeMB * 1024 * 1024) {
      DevExpress.ui.notify(
        this.localization.translateOnce("forms.max_file_size_exceeded", [this.options.maxFileSizeMB.toString()]),
        "error",
        3000);

      this.input.value = null;
      return;
    }

    if (file.size > 50 * 1024 * 1024) {
      DevExpress.ui.notify(
        this.localization.translateOnce("forms.max_file_size_exceeded", ["50"]),
        "error",
        3000);

      this.input.value = null;
      return;
    }

    if (!this.isFileTypeValid(file)) {
      DevExpress.ui.notify(
        this.localization.translateOnce("forms.file_type_not_supported", [this.options.acceptType]),
        "error",
        3000);

      this.input.value = null;
      return;
    }

    this.taskQueue.queueTask(() => {
      this.file
        .upload(file)
        .then(r => {
          if (!r) {
            return;
          }

          const key = r;
          this.input.value = null
          this.currentValue = key;
          
          this.setValue(key);
          this.dispatchUploadedEvent(file, key);
        })
        .catch(() => {
          this.input.value = null
        });
    });
  }
  private isFileTypeValid(file: File): boolean {
    if (!this.options || !this.options.acceptType) {
      return true;
    }

    let isValid = false;
    if (this.options.acceptType.indexOf("/") >= 0) {
      let acceptType = this.options.acceptType;

      const indexOfWildcard = acceptType.indexOf("/*");
      if (indexOfWildcard >= 0) {
        acceptType = acceptType.substr(0, indexOfWildcard + 1)
          .concat(".")
          .concat(acceptType.substr(indexOfWildcard + 1));
      }

      isValid = new RegExp("^".concat(acceptType).concat("$")).test(file.type);
    } else {
      const tokens = this.options.acceptType.toLowerCase().split(",");
      isValid = tokens.some(c => file.name.toLowerCase().endsWith(c));
    }

    return isValid;
  }
  private dispatchUploadedEvent(file, key) {
    const event = new CustomEvent("file-uploaded", {
      detail: {
        sender: this,
        value: {
          file: file,
          fileKey: key
        }
      },
      bubbles: true
    });

    this.element.dispatchEvent(event);
  }
}
