import { autoinject, computedFrom, transient } from "aurelia-framework";
import { ICommandData } from "../../../../framework/forms/export";
import { FormBase } from "../../../../framework/forms/form-export";
import { Share } from "../../../elements/share/share";
import { BenutzerTyp } from "../../../enumerations/benutzer-typ";
import { StartupService } from "../../../services/startup-service";

@autoinject
@transient()
export class PersonReadUtils {
  constructor(
    public startupService: StartupService
  ) { }

  form: FormBase;
  
  actionCommand: ICommandData = {
    id: "actionCommand",
    icon: "fas fa-share-alt",
    tooltip: "zit.aktionen",
    sortIndex: 99,
    isVisibleExpression: "r_share.canShare",
    execute: (executeOptions) => {
      const share: Share = this.form["r_share"];
      share.showCtxMenu(executeOptions.event);
    }
  };

  @computedFrom("startupService.startupInfo.Benutzer.Benutzertyp")
  get showNotizen() {
    switch (this.startupService.startupInfo.Benutzer.Benutzertyp) {
      case BenutzerTyp.Administrator:
      case BenutzerTyp.InternerBenutzer:
      case BenutzerTyp.JuristischePerson:
        return true;
      default:
        return false;
    }
  }

  bind(form: FormBase) {
    this.form = form;
  }
}
