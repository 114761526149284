import * as fwx from "../../../framework/forms/form-export";
import {
    SchnupperterminListUtils
} from "./schnuppertermin-list-utils";
import {
    SchnupperterminAnlageGpUtils
} from "./schnuppertermin-anlage-gp-utils";

@fwx.autoinject
export class SchnupperterminListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_SchnupperterminListUtils: SchnupperterminListUtils,
        private $f_SchnupperterminAnlageGpUtils: SchnupperterminAnlageGpUtils) {
        super(element, formBaseImport);
        this.id = "schnupperterminList";
        this.title = "schnupperterminList.schnupperterminlist_caption";
        this.addCommand({
            "binding": {
                "bindTo": "$f_SchnupperterminListUtils.schnupperterminReportAuswahlCommand",
                "bindToFQ": "functions.$f_SchnupperterminListUtils.schnupperterminReportAuswahlCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_SchnupperterminAnlageGpUtils.anlageGpCommand",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.anlageGpCommand"
            }
        });
        this.addFunction("$f_SchnupperterminListUtils", $f_SchnupperterminListUtils, "functions.$f_SchnupperterminListUtils");
        this.addFunction("$f_SchnupperterminAnlageGpUtils", $f_SchnupperterminAnlageGpUtils, "functions.$f_SchnupperterminAnlageGpUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_gpAnlage",
            "options": {
                "optionsName": "r_gpAnlageOptions",
                "optionsNameFQ": "r_gpAnlageOptions"
            },
            "caption": "schnupperterminList.r_gpanlage_caption",
            "height": "auto",
            "width": "900px",
            "showCloseButton": true,
            "commands": [{
                "binding": {
                    "dataContext": "$f_SchnupperterminAnlageGpUtils",
                    "bindTo": "saveGpCommand",
                    "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.saveGpCommand"
                }
            }]
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "r_gpValidationGroup",
            "options": {
                "optionsName": "r_gpValidationGroupOptions",
                "optionsNameFQ": "r_gpValidationGroupOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 200,
            "caption": "schnupperterminList.r_gpname1_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Name1",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Name1",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gpName1",
            "options": {
                "optionsName": "r_gpName1Options",
                "optionsNameFQ": "r_gpName1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 200,
            "caption": "schnupperterminList.r_gpname2_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Name2",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Name2",
                "propertyPrefix": "model"
            },
            "validationRules": [],
            "id": "r_gpName2",
            "options": {
                "optionsName": "r_gpName2Options",
                "optionsNameFQ": "r_gpName2Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "mode": "email",
            "caption": "schnupperterminList.r_gpemail_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Email",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Email",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gpEmail",
            "options": {
                "optionsName": "r_gpEmailOptions",
                "optionsNameFQ": "r_gpEmailOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "schnupperterminList.r_gpvorwahl_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Vorwahl",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Vorwahl",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gpVorwahl",
            "options": {
                "optionsName": "r_gpVorwahlOptions",
                "optionsNameFQ": "r_gpVorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "schnupperterminList.r_gptelefon_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Telefon",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Telefon",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gpTelefon",
            "options": {
                "optionsName": "r_gpTelefonOptions",
                "optionsNameFQ": "r_gpTelefonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 500,
            "mode": "url",
            "caption": "schnupperterminList.r_gpwebseite_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Website",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Website",
                "propertyPrefix": "model"
            },
            "validationRules": [],
            "id": "r_gpWebseite",
            "options": {
                "optionsName": "r_gpWebseiteOptions",
                "optionsNameFQ": "r_gpWebseiteOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 90,
            "caption": "schnupperterminList.r_gpstrasse_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Strasse",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Strasse",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gpStrasse",
            "options": {
                "optionsName": "r_gpStrasseOptions",
                "optionsNameFQ": "r_gpStrasseOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 10,
            "caption": "schnupperterminList.r_gphausnummer_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Hausnummer",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Hausnummer",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gpHausnummer",
            "options": {
                "optionsName": "r_gpHausnummerOptions",
                "optionsNameFQ": "r_gpHausnummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "staat",
            "filters": [],
            "caption": "schnupperterminList.r_gpland_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.IdLand",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.IdLand",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gpLand",
            "options": {
                "optionsName": "r_gpLandOptions",
                "optionsNameFQ": "r_gpLandOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 10,
            "caption": "schnupperterminList.r_gpplz_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Plz",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Plz",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gpPlz",
            "options": {
                "optionsName": "r_gpPlzOptions",
                "optionsNameFQ": "r_gpPlzOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "schnupperterminList.r_gport_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Ort",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Ort",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gpOrt",
            "options": {
                "optionsName": "r_gpOrtOptions",
                "optionsNameFQ": "r_gpOrtOptions"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "geschlechtMitTeam",
            "filters": [],
            "caption": "schnupperterminList.r_apgeschlecht_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.ApGeschlecht",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.ApGeschlecht",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_apGeschlecht",
            "options": {
                "optionsName": "r_apGeschlechtOptions",
                "optionsNameFQ": "r_apGeschlechtOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "schnupperterminList.r_apvorname_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.ApVorname",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.ApVorname",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_apVorname",
            "options": {
                "optionsName": "r_apVornameOptions",
                "optionsNameFQ": "r_apVornameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "schnupperterminList.r_apnachname_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.ApNachname",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.ApNachname",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_apNachname",
            "options": {
                "optionsName": "r_apNachnameOptions",
                "optionsNameFQ": "r_apNachnameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "schnupperterminList.r_apteam_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.ApTeam",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.ApTeam",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_apTeam",
            "options": {
                "optionsName": "r_apTeamOptions",
                "optionsNameFQ": "r_apTeamOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "mode": "email",
            "caption": "schnupperterminList.r_apemail_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.ApEmail",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.ApEmail",
                "propertyPrefix": "model"
            },
            "validationRules": [],
            "id": "r_apEmail",
            "options": {
                "optionsName": "r_apEmailOptions",
                "optionsNameFQ": "r_apEmailOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "schnupperterminList.r_apvorwahl_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.ApVorwahl",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.ApVorwahl",
                "propertyPrefix": "model"
            },
            "validationRules": [],
            "id": "r_apVorwahl",
            "options": {
                "optionsName": "r_apVorwahlOptions",
                "optionsNameFQ": "r_apVorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "schnupperterminList.r_aptelefon_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.ApTelefon",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.ApTelefon",
                "propertyPrefix": "model"
            },
            "validationRules": [],
            "id": "r_apTelefon",
            "options": {
                "optionsName": "r_apTelefonOptions",
                "optionsNameFQ": "r_apTelefonOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "landvorwahl",
            "filters": [],
            "caption": "schnupperterminList.r_apmobilvorwahl_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.ApMobilVorwahl",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.ApMobilVorwahl",
                "propertyPrefix": "model"
            },
            "validationRules": [],
            "id": "r_apMobilVorwahl",
            "options": {
                "optionsName": "r_apMobilVorwahlOptions",
                "optionsNameFQ": "r_apMobilVorwahlOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "schnupperterminList.r_apmobiltelefon_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.ApMobilTelefon",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.ApMobilTelefon",
                "propertyPrefix": "model"
            },
            "validationRules": [],
            "id": "r_apMobilTelefon",
            "options": {
                "optionsName": "r_apMobilTelefonOptions",
                "optionsNameFQ": "r_apMobilTelefonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "schnupperterminList.r_apfunktion_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.Funktion",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.Funktion",
                "propertyPrefix": "model"
            },
            "validationRules": [],
            "id": "r_apFunktion",
            "options": {
                "optionsName": "r_apFunktionOptions",
                "optionsNameFQ": "r_apFunktionOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lehrberuf",
            "filters": [],
            "caption": "schnupperterminList.r_lblehrberuf_caption",
            "binding": {
                "dataContext": "$f_SchnupperterminAnlageGpUtils",
                "bindTo": "model.IdLehrberuf",
                "bindToFQ": "functions.$f_SchnupperterminAnlageGpUtils.model.IdLehrberuf",
                "propertyPrefix": "model"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lbLehrberuf",
            "options": {
                "optionsName": "r_lbLehrberufOptions",
                "optionsNameFQ": "r_lbLehrberufOptions"
            }
        });
        super.onConstructionFinished();
    }
}